import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetDoc,
  apiFavoriteDoc,
  apiUnfavoriteDoc,
} from './api';

import {
  actionTypes,
  getDocRequest,
  getDocSuccess,
  getDocError,
  updateNewsArticle,
} from './actions';

function* getDocSaga({ id, isProject }) {
  try {
    const { user } = yield select(({ userService }) => userService);

    yield put(getDocRequest());

    const { data: { data: doc } } = yield call(() => apiGetDoc(id, {
      currentUserGlobalId: user.globalId,
      isProject
    }));

    yield put(getDocSuccess(doc));
  } catch (error) {
    console.log('GET DOC ERROR', error);
    yield put(getDocError(error));
  }
}

function* favoriteDocSaga({ docId }) {
  try {
    const { doc } = yield select(({ docService }) => docService);
    const { user } = yield select(({ userService }) => userService);

    // Fav Doc in state right away
    yield put(updateNewsArticle({
      doc: {
        ...doc,
        isFavorited: true,
      },
    }));

    // Fav API call
    yield call(() => apiFavoriteDoc({
      docId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('Favorite Doc ERROR', error);
  }
}


function* unfavoriteDocSaga({ docId }) {
  try {
    const { doc } = yield select(({ docService }) => docService);
    const { user } = yield select(({ userService }) => userService);

    // Unfav Doc in state right away
    yield put(updateNewsArticle({
      doc: {
        ...doc,
        isFavorited: false,
      },
    }));

    // Unfav API call
    yield call(() => apiUnfavoriteDoc({
      docId,
      userGlobalId: user.globalId,
    }));
  } catch (error) {
    console.log('Unfav doc ERROR', error);
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOC, getDocSaga);
  yield takeEvery(actionTypes.FAVORITE_DOC, favoriteDocSaga);
  yield takeEvery(actionTypes.UNFAVORITE_DOC, unfavoriteDocSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}

const baseAPIUrlProxy = process.env.API_URL_PROXY;
const basePublicAPIUrlProxy = process.env.PUBLIC_API_URL_PROXY;
const baseAPIUrlAuth = process.env.API_URL_AUTH;
const baseAPIUrlEmail = "/email";

export default {
  authApi: {
    login: `${baseAPIUrlAuth}/login`,
    check: `${baseAPIUrlAuth}/check`,
  },
  clientApi: {
    socket: {
      listen: process.env.API_URL_WS,
    },
    email: {
      support: `${baseAPIUrlEmail}/support`,
    },
    ai: {
      getLoginUrl: `${baseAPIUrlProxy}/ai/generate-login-url`,
      notifyOnNewUser: `${baseAPIUrlProxy}/ai/notify-on-new-user`,
    },
    users: {
      current: `${baseAPIUrlProxy}/users/current`,
      checkCurrentUserIsBlocked: `${baseAPIUrlProxy}/users/current/check-is-blocked`,
      featured: {
        post: `${baseAPIUrlProxy}/users/featured/post`,
        list: `${baseAPIUrlProxy}/users/featured/list`,
        delete: `${baseAPIUrlProxy}/users/featured/delete`,
      },
      update: `${baseAPIUrlProxy}/users`,
      setIsStartedUsingAI: `${baseAPIUrlProxy}/users/set-is-started-using-ai`,
      leaders: `${baseAPIUrlProxy}/users/leaders/list`,
    },
    usersUnits: {
      list: `${baseAPIUrlProxy}/users-units`,
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/users-units/${usersUnitId}`,
    },
    news: {
      list: `${baseAPIUrlProxy}/news`,
      item: (id = ":id") => `${baseAPIUrlProxy}/news/${id}`,
      like: `${baseAPIUrlProxy}/news/like`,
      dislike: `${baseAPIUrlProxy}/news/dislike`,
      read: `${baseAPIUrlProxy}/news/read`,
      unreadList: `${baseAPIUrlProxy}/news/unread`,
      decrementReadsUnique: `${baseAPIUrlProxy}/news/decrement-reads-unique`,
      favorites: `${baseAPIUrlProxy}/news/favorites`,
    },
    newsPublic: {
      read: `${basePublicAPIUrlProxy}/news/read`,
      item: (id = ":id") => `${basePublicAPIUrlProxy}/news/${id}`,
    },
    newsComments: {
      list: `${baseAPIUrlProxy}/news-comments`,
      create: `${baseAPIUrlProxy}/news-comments`,
      update: `${baseAPIUrlProxy}/news-comments`,
      delete: `${baseAPIUrlProxy}/news-comments`,
    },
    newsCommentsReactions: {
      create: `${baseAPIUrlProxy}/news-comments-reactions`,
      delete: `${baseAPIUrlProxy}/news-comments-reactions`,
    },
    newsCommentsPublic: {
      list: `${basePublicAPIUrlProxy}/news-comments`,
    },
    newsArticleUsersReads: {
      list: `${baseAPIUrlProxy}/news-users-reads`,
    },
    projectsNews: {
      list: `${baseAPIUrlProxy}/projects-news`,
      item: (id = ":id") => `${baseAPIUrlProxy}/projects-news/${id}`,
    },
    projectsNewsSearch: {
      search: `${baseAPIUrlProxy}/projects-news-search/search`,
      searchDatabase: `${baseAPIUrlProxy}/projects-news-search/search/db`,
      autocomplete: `${baseAPIUrlProxy}/projects-news-search/autocomplete`,
    },
    docs: {
      list: `${baseAPIUrlProxy}/docs`,
      listCulture: `${baseAPIUrlProxy}/docs/culture`,
      listTeam: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}`,
      listTeamFolder: (
        usersUnitId = ":usersUnitId",
        docsFolderId = ":docsFolderId"
      ) =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}/docs-folder/${docsFolderId}`,
      listTeamWithoutFolder: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs/team/${usersUnitId}/without-folder`,
      listFavourites: `${baseAPIUrlProxy}/docs/favourites`,
      item: (id = ":id") => `${baseAPIUrlProxy}/docs/${id}`,
      favorite: `${baseAPIUrlProxy}/docs/favorite`,
      unfavorite: `${baseAPIUrlProxy}/docs/unfavorite`,
      read: (docId = ":docId") => `${baseAPIUrlProxy}/docs/read/${docId}`,
      projects: {
        list: `${baseAPIUrlProxy}/docs/projects`,
        item: (id = ":id") => `${baseAPIUrlProxy}/docs/projects/${id}`,
        categories: {
          list: `${baseAPIUrlProxy}/docs-projects-categories`,
          item: (id = ":id") => `${baseAPIUrlProxy}/docs-projects-categories/${id}`,  
        }
      }
    },
    docsSearch: {
      search: `${baseAPIUrlProxy}/docs-search/search`,
      searchDatabase: `${baseAPIUrlProxy}/docs-search/search/db`,
      autocomplete: `${baseAPIUrlProxy}/docs-search/autocomplete`,
    },
    docsFolders: {
      list: `${baseAPIUrlProxy}/docs-folders`,
      item: (id = ":id") => `${baseAPIUrlProxy}/docs-folders/${id}`,
    },
    docsChangelogs: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs-changelogs/${usersUnitId}`,
    },
    docsTeamRead: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docsTeamRead/${usersUnitId}`,
      post: `${baseAPIUrlProxy}/docsTeamRead`,
      all: `${baseAPIUrlProxy}/docsTeamRead/all`,
    },
    iSpring: {
      users: `${baseAPIUrlProxy}/ispring/user`,
      courses: `${baseAPIUrlProxy}/ispring/content`,
      courseModules: `${baseAPIUrlProxy}/ispring/learners/modules/results`,
      userResults: `${baseAPIUrlProxy}/ispring/learners/results`,
    },
    stories: {
      list: `${baseAPIUrlProxy}/stories`,
    },
    champions: {
      list: `${baseAPIUrlProxy}/champions`,
      item: (id = ":id") => `${baseAPIUrlProxy}/champions/${id}`,
      like: `${baseAPIUrlProxy}/champions/like`,
      dislike: `${baseAPIUrlProxy}/champions/dislike`,
      read: `${baseAPIUrlProxy}/champions/read`,
    },
    championsComments: {
      list: `${baseAPIUrlProxy}/champions-comments`,
      create: `${baseAPIUrlProxy}/champions-comments`,
      update: `${baseAPIUrlProxy}/champions-comments`,
      delete: `${baseAPIUrlProxy}/champions-comments`,
    },
    championsArticleUsersReads: {
      list: `${baseAPIUrlProxy}/champions-users-reads`,
    },
    championsList: {
      list: `${baseAPIUrlProxy}/champions-list`,
    },
    championsListLink: {
      item: `${baseAPIUrlProxy}/champions-list-link`,
    },
    foldersCourses: {
      list: `${baseAPIUrlProxy}/folders-courses`,
      create: `${baseAPIUrlProxy}/folders-courses`,
      delete: `${baseAPIUrlProxy}/folders-courses`,
      update: `${baseAPIUrlProxy}/folders-courses`,
    },
    folderQueue: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/folder-queue/${usersUnitId}`,
      create: `${baseAPIUrlProxy}/folder-queue`,
      delete: `${baseAPIUrlProxy}/folder-queue`,
      update: `${baseAPIUrlProxy}/folder-queue`,
    },
    docsQueue: {
      item: (usersUnitId = ":usersUnitId") =>
        `${baseAPIUrlProxy}/docs-queue/${usersUnitId}`,
    },
    webinars: {
      list: `${baseAPIUrlProxy}/webinars`,
    },
    usersTasks: {
      list: `${baseAPIUrlProxy}/users-tasks`,
      create: `${baseAPIUrlProxy}/users-tasks`,
      update: `${baseAPIUrlProxy}/users-tasks`,
      item: (id = ":id") => `${baseAPIUrlProxy}/users-tasks/${id}`,
    },
    usersTasksHistory: {
      create: `${baseAPIUrlProxy}/users-tasks-history`,
      item: (taskId = ":taskId") =>
        `${baseAPIUrlProxy}/users-tasks-history/${taskId}`,
    },
    usersTasksDocs: {
      create: `${baseAPIUrlProxy}/users-tasks-docs`,
      delete: `${baseAPIUrlProxy}/users-tasks-docs`,
      change: `${baseAPIUrlProxy}/users-tasks-docs`,
      item: (taskId = ":taskId") =>
        `${baseAPIUrlProxy}/users-tasks-docs/${taskId}`,
    },
    usersTasksComments: {
      list: `${baseAPIUrlProxy}/users-tasks-comments`,
      create: `${baseAPIUrlProxy}/users-tasks-comments`,
      update: `${baseAPIUrlProxy}/users-tasks-comments`,
      delete: `${baseAPIUrlProxy}/users-tasks-comments`,
    },
    usersFavorites: {
      list: `${baseAPIUrlProxy}/users-favorites`,
      create: `${baseAPIUrlProxy}/users-favorites`,
      update: `${baseAPIUrlProxy}/users-favorites`,
      delete: `${baseAPIUrlProxy}/users-favorites`,
    },
    telegramBot: {
      sendMessage: `${baseAPIUrlProxy}/telegram-bot`,
    },
    teams: {
      list: `${baseAPIUrlProxy}/teams`,
    },
    usefulProfile: {
      list: `${baseAPIUrlProxy}/useful-profile`,
      item: (id = ":id") => `${baseAPIUrlProxy}/useful-profile/${id}`,
    },
    usefulStandarts: {
      list: `${baseAPIUrlProxy}/useful-standarts`,
    },
    usefulAvatars: {
      list: `${baseAPIUrlProxy}/useful-avatars`,
    },
    learningMap: {
      list: `${baseAPIUrlProxy}/learning-map`,
    },
    usefulVoice: {
      list: `${baseAPIUrlProxy}/useful-voice`,
    },
  },
};

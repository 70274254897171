import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { observer } from 'mobx-react-lite';
import { parseCookies, setCookie } from 'nookies';

import TopNav from '../TopNav';
import SideNav from '../SideNav';
import Footer from '../Footer';
import PopupNewUsers from '../PopupNewUsers/index.tsx';
import PopupLegal from '../PopupLegal/index.tsx';
import NotificationsPopup from '../NotificationsPopup/index.tsx';

import S from './styles';

import UserStore from '../../../stores/user.tsx';
import WebsocketStore from '../../../stores/websocket.tsx';

import {
  getUsers,
  resetState as resetStateUsers,
} from '../../../services/iSpringUsers/actions';

import {
  getUserResults,
  resetState as resetStateUserResults,
} from '../../../services/iSpringUserResults/actions';

const LEGAL_POPUP_COOKIE_NAME = 'IS_HIDE_LEGAL_POPUP_LKDP_UNTIL_MIDNIGHT';

const PrivateLayout = observer(({ children, isAuthorized, user }) => {
  const [isOpenPopupNewUsers, setIsOpenPopupNewUsers] = useState(false);
  const [isOpenPopupLegal, setIsOpenPopupLegal] = useState(false);

  const { updateUserAction } = UserStore;

  const { notifications } = WebsocketStore;

  const checkShowUserLegalPopup = () => {
    const cookies = parseCookies(); 

    if (cookies[LEGAL_POPUP_COOKIE_NAME] !== 'true') {
      setIsOpenPopupLegal(true);
    }
  }

  useEffect(() => {
    if (user) {
      const data = { ...user };
      const startingPoint = new Date('08/25/2023 12:00:00');
      const start = new Date(data.employmentStartDate);

      if (
        startingPoint.getTime() < start.getTime()
        && !data.popupNewUser.checkedPopup.status
      ) {
        setIsOpenPopupNewUsers(true);

        const currentDate = new Date().toISOString().split('T')[0];

        data.popupNewUser.checkedPopup.status = true;
        data.popupNewUser.checkedPopup.date = currentDate;
        updateUserAction(data);
      } else {
        checkShowUserLegalPopup();
      }
    }
  }, [user]);

  const onClosePopupLegal = () => {
    setIsOpenPopupLegal(false);

    const date = new Date();
    date.setHours(24, 0,0,0); // today midnight

    setCookie(null, LEGAL_POPUP_COOKIE_NAME, 'true', {
      expires: date, // today at 00:00
      path: '/',
    })
  }

  return (
    <S.Container>
      <S.Wrapper>
        <S.TopNav>
          <TopNav isAuthorized={isAuthorized} />
        </S.TopNav>

        <SideNav isAuthorized={isAuthorized} />

        {isOpenPopupNewUsers && <PopupNewUsers close={() => {
          setIsOpenPopupNewUsers(false);

          checkShowUserLegalPopup();
        }} />}

        {isOpenPopupLegal && <PopupLegal onClose={onClosePopupLegal} />}

        <S.Content>{children}</S.Content>

        {notifications.map((el, i) => (
          <div key={el.id}>
            <NotificationsPopup data={el} index={i} />
          </div>
        ))}

        <Footer />
      </S.Wrapper>
    </S.Container>
  );
});

PrivateLayout.propTypes = {
  children: PropTypes.node,
};

PrivateLayout.defaultProps = {
  children: null,
};

const mapStateToProps = ({ userService }) => {
  const { user } = userService;

  return {
    user,
  };
};

const mapDispatchToProps = {
  getUsersAction: getUsers,
  resetStateUsersAction: resetStateUsers,

  getUserResultsAction: getUserResults,
  resetStateUserResultsAction: resetStateUserResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);

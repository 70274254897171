import styled from 'styled-components';

const S = {};

S.Container = styled.div`
  @keyframes anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: anim 1s linear;
`;

S.Window = styled.div`
  position: relative;
  width: 70%;
  max-width: 800px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000;
  transition: color 0.2s ease;
  cursor: pointer;

  svg {
    fill: #000;
    transition: opacity 0.2s ease;
  }

  &:hover {
    svg {
      opacity: 0.7;
    }
  }
`;

S.Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 5% 8%;
`;

S.Title = styled.h2`
  margin-bottom: 30px;
  padding-right: 100px;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.03em;
  font-weight: 600;
  color: #000;
  cursor: default;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

S.Text = styled.div`
  width: 70%;
  font-size: 20px;
  color: #000;
  cursor: default;

  @media (max-width: 1000px) {
    font-size: 16px;
    width: 100%;
  }

  p {
    margin-bottom: 1.5em;
  }

  strong {
    font-weight: 600;
  }
`;

S.ParagraphSmallText = styled.p`
  font-size: 12px;
  
  /* @media (max-width: 1000px) {
    font-size: 11px;
  } */
`;

export default S;

import React from "react";

import localIcons from "./icons";
import S from "./styles";

function PopupLegal(props) {
  const { onClose } = props;

  return (
    <S.Container>
      <S.Window>
        <S.Close onClick={onClose}>{localIcons.close}</S.Close>

        <S.Content>
          <S.Title>Обращаем ваше внимание ❗️</S.Title>
          
          <S.Text>
            <p>Электронные сообщения и любые документы, размещённые на этом сайте, содержат конфиденциальную информацию.<br/><strong>Разглашение* строго запрещено.</strong></p>  
            <p>Согласно пунктам 3.2 и&nbsp;12.1 Положения о&nbsp;коммерческой тайне (&#8470;П 1.3.1 от&nbsp;10.01.2022), информация составляет коммерческую тайну. <strong>Нарушение данных ограничений влечёт административную ответственность</strong>, предусмотренную законодательством&nbsp;РФ.</p>
            <S.ParagraphSmallText>*Разглашение информации, составляющей коммерческую тайну,&nbsp;&mdash; действие или бездействие, в&nbsp;результате которых информация, составляющая коммерческую тайну, в&nbsp;любой возможной форме становится известна третьим лицам без согласия обладателя такой информации либо вопреки трудовому или гражданско-правовому договору.</S.ParagraphSmallText>  
          </S.Text>
        </S.Content>
      </S.Window>
    </S.Container>
  );
}

export default PopupLegal;
